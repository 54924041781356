import { useCallback } from "react";

export function useSetSessionStorage() {
  const setSessionStorage = useCallback((key, value) => {
    try {
      const valueToStore = JSON.stringify(value);
      sessionStorage.setItem(key, valueToStore);
    } catch (e) {
      console.error("Error saving to sessionStorage:", e);
    }
  }, []);

  return setSessionStorage;
}

export function useGetSessionStorage() {
  const getSessionStorage = useCallback((key) => {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue) {
      try {
        return JSON.parse(storedValue);
      } catch (e) {
        console.error("Error parsing sessionStorage item:", e);
      }
    }
    return null;
  }, []);

  return getSessionStorage;
}

export function useRemoveSessionStorage() {
  const removeSessionStorage = useCallback((key) => {
    sessionStorage.removeItem(key);
  }, []);

  return removeSessionStorage;
}

/*
USAGE

const MyComponent = () => {
  const setSessionStorage = useSetSessionStorage();
  const getSessionStorage = useGetSessionStorage();
  const removeSessionStorage = useRemoveSessionStorage();

  // To set a value
  setSessionStorage('myKey', { some: 'value' });
  
  // To get a value
  const storedValue = getSessionStorage('myKey');

  // To remove a value
  removeSessionStorage('myKey');

  // Rest of your component logic
};
*/
