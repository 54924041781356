import "../../styles.css";
import styled from "styled-components";

const Promotions = ({ promotions, layout }) => {
  // Add auto scroll logic from work or add pagination

  const currentDate = new Date().toISOString();
  const livePromotions = promotions?.filter(
    (promo) => promo?.endDate > currentDate
  );
  console.log("ANDY asdasd", { promotions, livePromotions });
  return (
    <>
      <PromotionsContainer
        className={"promotions-container " + layout?.promotions?.container}
        promoamount={livePromotions?.length}
      >
        {livePromotions.map((promotion, i) => {
          // endDate
          // promoCode
          // startDate

          return (
            <Promotion
              key={
                i + promotion?._id + promotion?.name + promotion?.description
              }
              className={
                "promotions-promotion " + layout?.promotions?.promotion
              }
              backgroundcolour={promotion?.backgroundColor}
              backgroundimage={promotion?.backgroundImage}
            >
              <PromotionTitle
                className={"promotions-title " + layout?.promotions?.title}
              >
                {promotion?.name}
              </PromotionTitle>
              <PromotionInfo
                className={"promotion-info " + layout?.promotions?.info}
              >
                {promotion?.description}
              </PromotionInfo>
            </Promotion>
          );
        })}
      </PromotionsContainer>
    </>
  );
};

export default Promotions;

//
//
//
//
//
//

const PromotionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ promoamount }) => promoamount && promoamount},
    100%
  );
  width: 100%;
  height: 150px;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  /* width */
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const Promotion = styled.div`
  background-image: url(${({ backgroundimage }) =>
    backgroundimage && backgroundimage});
  background: ${({ backgroundcolour, backgroundimage }) =>
    backgroundcolour && !backgroundimage && backgroundcolour};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  width: 100%;
  height: 100%;

  display: grid;
  //   align-items: center;
  justify-content: center;
  text-align: center;
  scroll-snap-align: center;
`;
const PromotionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 12px;

  font-size: 24px;
`;
const PromotionInfo = styled.div`
  font-size: 12px;
`;
