import React, { useState } from "react";

const Viva = ({ orderAmount }) => {
  const [formData, setFormData] = useState({
    amount: "",
    customerTrns: "",
    email: "",
    fullName: "",
    phone: "",
    countryCode: "CY", // Defaulting to Cyprus
    requestLang: "el-GR", // Defaulting to Greek, use en-GB for UK?
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);

    // Here you would call the createVivaOrder function
    // or integrate the fetch call directly within this function
    // Remember to handle the response and any errors
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Amount:</label>
        <input
          type="number"
          name="amount"
          value={orderAmount || formData.amount}
          //   onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Description:</label>
        <input
          type="text"
          name="customerTrns"
          value={formData.customerTrns}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Full Name:</label>
        <input
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Phone:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Country Code:</label>
        <select
          name="countryCode"
          value={formData.countryCode}
          onChange={handleChange}
        >
          {/* Add options for each country code you support */}
          <option value="GR">Greece</option>
          <option value="CY">Cyprus</option>
          <option value="GB">United Kingdom</option>
          {/* Add more options as needed */}
        </select>
      </div>
      <div>
        <label>Language:</label>
        <select
          name="requestLang"
          value={formData.requestLang}
          onChange={handleChange}
        >
          <option value="el-GR">Greek</option>
          <option value="el-CY">Cyprus</option>
          <option value="en-US">English</option>
          {/* Add more options as needed */}
        </select>
      </div>
      <button type="submit">Submit Payment</button>
    </form>
  );
};

export default Viva;

/* BE integration in node.js
Step 1: Create a Server-Side Endpoint
You should set up a server-side endpoint (using Node.js, PHP, or another backend technology) that your React app can call. This endpoint will be responsible for making the API request to Viva Payments. Below is an example using Express in Node.js, which is commonly used with React applications:

const express = require('express');
const axios = require('axios');
const bodyParser = require('body-parser');

const app = express();
const port = 3001; // Ensure this port is free or set to your preference

app.use(bodyParser.json());

app.post('/create-viva-order', async (req, res) => {
  const accessToken = 'xxx'; // Your actual access token
  const postFields = {
    ...req.body,
    // Add or modify any fields as per your requirement
  };

  try {
    const response = await axios.post('https://demo-api.vivapayments.com/checkout/v2/orders', postFields, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
    });
    res.json(response.data);
  } catch (error) {
    res.status(500).json({ error: error.toString() });
  }
});

app.listen(port, () => {
  console.log(`Server running on port ${port}`);
});
Ensure you have express and axios installed in your Node.js project:


npm install express axios body-parser

Step 2: Call the Server-Side Endpoint from Your React App
In your React application, you can call this endpoint when needed, such as during the checkout process. Here's an example using fetch:

const createVivaOrder = async () => {
  const orderDetails = {
    amount: 100,
    customerTrns: 'Description for the customer',
    // Add other fields as per your requirement
  };

  try {
    const response = await fetch('/create-viva-order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderDetails),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log(data); // Handle the response data
  } catch (error) {
    console.error('Error creating Viva order:', error);
  }
};
This function can be called from your React components, for example, when a user submits their order. Make sure to adjust the URL ('/create-viva-order') if your server is running on a different port or if you have a more complex setup.
*/
