//styles
import "../../styles.css";

import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { useSelector } from "react-redux";

//components
import Header from "../store/Header";
import Payments from "./Payments";
import DeliveryMethod from "./DeliveryMethod";

export const Item = ({ item, i, layout }) => {
  const id = item?.id;
  const quantity = item?.quantity;
  const itemDetails = item?.payload?.item;
  const itemExtras = item?.payload?.itemExtras;

  let itemExtrasTotal = 0;

  if (itemExtras) {
    Object.values(itemExtras)?.forEach((value) => {
      const [_type, cost] = value?.split(",");
      itemExtrasTotal += Number(cost);
    });
  }
  return (
    <ItemContainer
      key={i + id + itemDetails?.name}
      className={"cart-item " + layout?.cart?.item}
    >
      <div
        style={{
          "margin-bottom": "12px",
        }}
        className={"cart-itemName " + layout?.cart?.itemName}
      >
        {itemDetails?.name}
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end" }}
        className={"cart-itemQuantity " + layout?.cart?.itemQuantity}
      >
        x{quantity}
      </div>
      {itemExtras && (
        <div style={{ "font-size": "14px" }}>
          {Object.entries(itemExtras)?.map(([key, value], i) => {
            const [type, cost] = value?.split(",");
            return (
              <div key={i + key + value}>
                {/* {`${key}: ${type} €${cost}`} */}
                {`${key}: ${type}`}
              </div>
            );
          })}
        </div>
      )}
      <div
        style={{
          "grid-column": "2 / 3",
          display: "grid",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        className={"cart-itemCost " + layout?.cart?.itemCost}
      >
        €
        {((Number(itemDetails?.price) + itemExtrasTotal) * quantity).toFixed(2)}
      </div>
    </ItemContainer>
  );
};

const Checkout = () => {
  const [deliveryMethod, setDeliveryMethod] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [buyersName, setBuyersName] = useState(null);
  const { items: cart } = useSelector((state) => state.cart);
  const { store } = useSelector((state) => state.store);
  const { layout, deliveryMethods, paymentMethods } = store;
  let location = useLocation();

  const { items } = location.state || [];

  console.log("ANDY state", { store, cart, selectedPaymentMethod, items });
  const navigate = useNavigate();

  useEffect(() => {
    setDeliveryMethod(deliveryMethods?.[0]?.value);
    setSelectedPaymentMethod(paymentMethods?.[0]?.value);
  }, [deliveryMethods, paymentMethods]);

  const calculateTotal = () => {
    let cartTotal = 0;

    cart?.forEach((item) => {
      const itemExtras = item?.payload?.itemExtras;
      let itemExtrasTotal = 0;
      if (itemExtras) {
        Object.values(itemExtras)?.forEach((value) => {
          const [_type, cost] = value?.split(",");
          itemExtrasTotal += Number(cost);
        });
      }

      cartTotal +=
        (Number(item?.payload?.item?.price) + itemExtrasTotal) * item?.quantity;
    });

    const livePromos = [
      //will get this array from database
      { code: "123", discount: 2, type: "-" },
      { code: "000", discount: 1, type: "-" },
      { code: "free", discount: 100, type: "%" },
      { code: "10off", discount: 10, type: "%" },
      { code: "20off", discount: 20, type: "%" },
      { code: "50off", discount: 50, type: "%" },
      { code: "80off", discount: 80, type: "%" },
    ];
    // can use .find() if it will always just 1 promo per order
    let isValidPromoCode = livePromos.filter(
      (promo) => promo?.code === promoCode
    );

    if (isValidPromoCode?.length > 0) {
      if (isValidPromoCode?.[0]?.type === "-") {
        return (cartTotal.toFixed(2) - isValidPromoCode?.[0]?.discount).toFixed(
          2
        );
      }
      if (isValidPromoCode?.[0]?.type === "%") {
        return (
          cartTotal.toFixed(2) -
          (cartTotal.toFixed(2) / 100) * isValidPromoCode?.[0]?.discount
        ).toFixed(2);
      }
    }

    return cartTotal.toFixed(2);
  };

  return (
    <>
      <div
        onClick={() => navigate(-1)}
        style={{ display: "inline-flex", alignItems: "center", gap: "10px" }}
      >
        <div>{"<"}</div>
        <h3>Checkout</h3>
      </div>
      {items?.map((item, i) => (
        <Item key={item?.id + i} item={item} i={i} layout={layout} />
      ))}
      <div>
        Name:{" "}
        <input
          type="text"
          placeholder="Name"
          onChange={(e) => setBuyersName(e.target.value)}
        />
        {/* if user is logged in set name as user?.firstName */}
      </div>
      <DeliveryMethod
        layout={layout}
        deliveryMethod={deliveryMethod}
        setDeliveryMethod={setDeliveryMethod}
        deliveryMethods={deliveryMethods}
      />
      <div>
        Promo code:{" "}
        <input
          type="text"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
        />
      </div>
      <div>
        Price breakdown: €{calculateTotal()}
        {/* Implement logic to apply promotions and rewards */}
      </div>
      <Payments
        selectedPaymentMethod={selectedPaymentMethod}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        paymentMethods={paymentMethods}
        orderAmount={calculateTotal()}
        deliveryMethod={deliveryMethod}
        buyersName={buyersName}
        promoCode={promoCode}
      />
    </>
  );
};

export default Checkout;

const ItemContainer = styled.div`
  outline: 1px solid blue;

  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
`;
