import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useWidth from "hooks/width.hook";

import { quote } from "./quotes";
const HeroImage = "https://picsum.photos/640/360";

const HeroWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 667px;
  background-image: url(${HeroImage});
  background-color: #bebebe;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const HeroText = styled.div`
  position: relative;
  text-align: center;
  color: white;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-family: "Jost", Arial, sans-serif;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 1.6px;
  margin: 0 20px;

  @media screen and (min-width: 992px) {
    width: 632px;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: 6.4px;
    margin: 0 auto;
  }
`;

const Login = React.memo(() => {
  const width = useWidth();
  const [selectedQuote, setSelectedQuote] = useState(quote[0]);

  useEffect(() => {
    setSelectedQuote(quote[Math.floor(Math.random() * quote.length)]);
  }, []);

  return (
    <HeroWrapper>
      <HeroText>
        <Title width={width}>{selectedQuote}</Title>
      </HeroText>
    </HeroWrapper>
  );
});

export default Login;
