// CreditCard.js
import React, { useState } from "react";
import dayjs from "dayjs";
const CreditCard = ({ orderAmount, onSubmit }) => {
  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  const handleChange = (e) => {
    setCardDetails({ ...cardDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(cardDetails);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Card Number</label>
        <input
          name="cardNumber"
          type="text"
          value={cardDetails.cardNumber}
          onChange={handleChange}
          placeholder="0000 0000 0000 0000"
          maxLength={16}
          required
        />
      </div>
      <div>
        <label>Expiry Date</label>
        <input
          name="expiryDate"
          type="text"
          value={cardDetails.expiryDate}
          onChange={handleChange}
          placeholder="MM/YY"
          maxLength={5}
          required
        />
      </div>
      <div>
        <label>CVV</label>
        <input
          name="cvv"
          type="password"
          value={cardDetails.cvv}
          onChange={handleChange}
          placeholder="---"
          maxLength={4}
          required
        />
      </div>
      <button type="submit">Submit</button>
      <div>{orderAmount}</div>
    </form>
  );
};

export default CreditCard;

/*
// This is a Node.js example for implementing the backend for this credit card form
const axios = require('axios');

const processPayment = async (cardDetails, amount) => {
  try {
    const response = await axios.post('https://api.jcc.com.cy/payments', {
      // You'll need to refer to JCC's API documentation for the exact field names and API endpoint
      amount: amount,
      currency: 'EUR',
      cardNumber: cardDetails.cardNumber,
      expiryDate: cardDetails.expiryDate,
      cvv: cardDetails.cvv,
      // Additional fields as required by JCC's API
    }, {
      headers: {
        'Authorization': `Bearer YOUR_SECRET_API_KEY`,
        // Other headers as required
      },
    });

    return response.data; // Or however you need to handle the response
  } catch (error) {
    console.error('Payment processing error:', error);
    throw error; // Handle errors appropriately in your application
  }
};

*/
