import React, { useEffect, useState, useRef } from "react";
import "../../styles.css";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { addItem } from "../../state/slices/cartSlice";

const Categories = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  layout,
}) => {
  return (
    <CategoriesContainer
      className={
        "menu-categories-container " + layout?.menu?.categories?.container
      }
      categoryAmount={["All", ...categories]?.length}
    >
      {["All", ...categories].map((category, i) => {
        return (
          <Category
            key={i + category}
            className={
              "menu-categories-category " + layout?.menu?.categories?.category
            }
            onClick={() => setSelectedCategory(category)}
            isActive={category === selectedCategory}
          >
            {category}
          </Category>
        );
      })}
    </CategoriesContainer>
  );
};

const AddToCartQty = ({ item, itemExtras, layout }) => {
  const [buttonStatus, setButtonStatus] = useState("idle"); // 'idle', 'progress', or 'ready'
  const [itemQty, setItemQty] = useState(0);

  const dispatch = useDispatch();

  // handle add to cart tick animation
  useEffect(() => {
    let timer;
    if (buttonStatus === "progress") {
      const svg = document.getElementById("check");
      svg.classList.add("progress");
      timer = setTimeout(() => {
        svg.classList.toggle("progress");
        svg.classList.toggle("ready");
        setButtonStatus("ready");
      }, 500);
      setTimeout(() => {
        setButtonStatus("idle");
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [buttonStatus]);

  const handleAddToCart = () => {
    if (itemQty > 0) {
      // dispatch(addItem({ id: item?.id, payload: item, quantity: itemQty }));
      dispatch(
        addItem({
          id: item?.id,
          payload: { item, itemExtras },
          quantity: itemQty,
        })
      ); // payload is an object, take extras too
      setButtonStatus("progress");
    } else {
      alert("Set a value or Dispatch RemoveItem");
    }
  };

  return (
    <AddToCartQtyContainer
      className={
        "menu-addToCart-container " + layout?.menu?.addToCart?.container
      }
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={
          "menu-addToCart-buttonText " + layout?.menu?.addToCart?.buttonText
        }
        style={{ padding: "12px", cursor: "pointer" }}
        onClick={() => handleAddToCart()}
      >
        {buttonStatus === "idle" ? (
          <span style={{ fontSize: "19px" }}>Add</span>
        ) : (
          <svg
            id="check"
            className={buttonStatus}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsSlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 100 100"
            xmlSpace="preserve"
          >
            <circle
              id="AddedCircle"
              cx="50"
              cy="50"
              r="46"
              fill="transparent"
            />
            <polyline
              id="AddedTick"
              points="25,55 45,70 75,33"
              fill="transparent"
            />
          </svg>
        )}
      </div>
      <div
        className={
          "menu-addToCart-buttonQty " + layout?.menu?.addToCart?.buttonQty
        }
      >
        <select
          name="quantity-select"
          id="qty-select"
          onChange={(e) => setItemQty(Number(e.target.value))}
        >
          {[...Array(10).keys()].map((qty) => (
            <option key={qty} value={qty}>
              {qty}
            </option>
          ))}
        </select>
      </div>
    </AddToCartQtyContainer>
  );
};

const MenuItem = ({ item, index, layout }) => {
  const [itemExtras, setItemExtras] = useState(null);
  const [itemModal, setItemModal] = useState(false);
  return (
    <>
      <MenuItemContainer
        key={`${index}_${item?.name}`}
        className={"menuItems-item " + layout?.menu?.menuItems?.item}
        onClick={() => setItemModal(true)}
      >
        <MenuItemName
          className={"menuItems-itemName " + layout?.menu?.menuItems?.itemName}
        >
          {item?.name}
        </MenuItemName>
        <MenuItemPrice
          className={
            "menuItems-itemPrice " + layout?.menu?.menuItems?.itemPrice
          }
        >
          €{item?.price}
        </MenuItemPrice>
        <MenuItemDescription
          className={
            "menuItems-itemDescription " +
            layout?.menu?.menuItems?.itemDescription
          }
        >
          {item?.description}
        </MenuItemDescription>
        <MenuItemImage
          className={
            "menuItems-itemImage " + layout?.menu?.menuItems?.itemImage
          }
          image={item?.image}
        />
        <MenuExtrasContainer
          className={
            "menuItems-extrasContainer " +
            layout?.menu?.menuItems?.extrasContainer
          }
        >
          {item?.extras?.map((extra, i) => {
            return (
              <div
                key={`${i}_${extra?.option}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
                className={
                  "menuItems-extraItemContainer " +
                  layout?.menu?.menuItems?.extraItemContainer
                }
                onClick={(e) => e.stopPropagation()}
              >
                <MenuExtrasLabel
                  className={
                    "menuItems-extraItemLabel " +
                    layout?.menu?.menuItems?.extraItemLabel
                  }
                  htmlFor={extra?.option}
                >
                  {extra?.option}
                </MenuExtrasLabel>
                <MenuExtrasSelect
                  className={
                    "menuItems-extraItemSelect " +
                    layout?.menu?.menuItems?.extraItemSelect
                  }
                  name={extra?.option}
                  id={extra?.option}
                  onChange={(e) =>
                    setItemExtras((prevState) => ({
                      ...prevState,
                      [extra?.option]: e.target.value,
                    }))
                  }
                >
                  {extra?.type?.map((type, i) => (
                    <MenuExtrasOption
                      key={`${i}_${extra?.option}_${type?.text}`}
                      value={[type?.text, type?.cost.toFixed(2)]}
                      className={
                        "menuItems-extraItemOption " +
                        layout?.menu?.menuItems?.extraItemOption
                      }
                    >
                      {type?.text}{" "}
                      {type?.cost > 0 && (
                        <span>(€{type?.cost.toFixed(2)})</span>
                      )}
                    </MenuExtrasOption>
                  ))}
                </MenuExtrasSelect>
              </div>
            );
          })}
        </MenuExtrasContainer>
        <AddToCartQty item={item} itemExtras={itemExtras} layout={layout} />
      </MenuItemContainer>
      {itemModal && (
        <ItemModalContainer>
          {
            console.log("ANDY item", { item, itemExtras, layout })
            /*
          {
    "extras": [
        {
            "option": "sugar",
            "type": [
                {
                    "text": "None",
                    "value": 0,
                    "cost": 0
                },
                {
                    "text": "1 teaspoon",
                    "value": 1,
                    "cost": 0.25
                },
                {
                    "text": "2 teaspoons",
                    "value": 2,
                    "cost": 0.5
                }
            ]
        },
        {
            "option": "milk",
            "type": [
                {
                    "text": "Full",
                    "value": 0,
                    "cost": 0
                },
                {
                    "text": "Half",
                    "value": 1,
                    "cost": 0
                },
                {
                    "text": "Oat",
                    "value": 2,
                    "cost": 0.25
                },
                {
                    "text": "Soya",
                    "value": 3,
                    "cost": 0.25
                },
                {
                    "text": "Almond",
                    "value": 4,
                    "cost": 0.25
                },
                {
                    "text": "None",
                    "value": 5,
                    "cost": 0
                }
            ]
        }
    ],
    "category": "Hot drinks"
}
          */
          }
          <div onClick={() => setItemModal(false)}>Close</div>
          {item?.image && (
            <div>
              <img
                src={item?.image}
                style={{ "object-fit": "cover", width: "100%", height: "50vh" }}
              />
            </div>
          )}
          <div
            style={{
              display: "grid",
              "grid-template-columns": "80% 20%",
              gap: "12px",
              padding: "10px",
            }}
          >
            <div style={{ "grid-column": "1 / 3", "font-size": "20px" }}>
              {item?.name}
            </div>
            <div>{item?.description}</div>
            <div>€{item?.price}</div>
          </div>
        </ItemModalContainer>
      )}
    </>
  );
};

const MenuItems = ({ content, layout }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  let categories = content?.menu?.categories || [];
  const { items: cartValue } = useSelector((state) => state.cart);

  const categoryRefs = useRef({});

  // Initialize refs for categories
  useEffect(() => {
    categoryRefs.current = categories.reduce((acc, value) => {
      acc[value] = React.createRef();
      return acc;
    }, {});
  }, [categories]);

  // Scroll on category click
  useEffect(() => {
    if (selectedCategory === "All") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else if (categoryRefs.current[selectedCategory]) {
      categoryRefs.current[selectedCategory].current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedCategory, categoryRefs]);

  console.log("ANDY cart", cartValue);

  return (
    <>
      <Categories
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={(e) => setSelectedCategory(e)}
        layout={layout}
      />
      {categories?.map((category, i) => {
        const items = content?.menu?.items?.[category];
        return (
          <MenuContainer
            key={`${i}_${category}`}
            className={
              "menuItems-menuContainer " +
              layout?.menu?.menuItems?.menuContainer
            }
            ref={categoryRefs.current[category]}
          >
            <MenuCategoryTitle
              className={"menuItems-title " + layout?.menu?.menuItems?.title}
            >
              {category}
            </MenuCategoryTitle>
            <MenuItemsContainer
              className={
                "menuItems-itemsContainer " +
                layout?.menu?.menuItems?.itemsContainer
              }
            >
              {items?.map((item, index) => (
                <MenuItem
                  key={index + item?.name + item?.price}
                  item={item}
                  index={index}
                  layout={layout}
                />
              ))}
            </MenuItemsContainer>
          </MenuContainer>
        );
      })}
    </>
  );
};

export default MenuItems;

//
//
//
//
//
//
//

//Menu styles
const MenuWrapper = styled.div`
  display: grid;
  // grid-template-columns: 1fr 1fr;
`;
const MenuContainer = styled.div`
  // outline: 1px solid red;
`;
const MenuCategoryTitle = styled.div`
  font-size: 20px;
  text-decoration: underline;
  margin-bottom: 24px;
`;
const MenuItemsContainer = styled.div`
  display: grid;
  // grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`;
const MenuItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto 100px;
  width: calc(100% - 48px);
  margin: 0 12px;
  padding: 12px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
const MenuItemName = styled.div`
  font-size: 18px;
`;
const MenuItemDescription = styled.div``;
const MenuItemPrice = styled.div``;
const MenuItemImage = styled.div`
  ${({ image }) =>
    image &&
    `
      background-image: url(${image});
      // background-color: rgba(0, 0, 0, 0.1);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      width: 100px;
      height: 100px;

      grid-row: 2/4;
      grid-column: 2/3;
    `}
`;
const MenuExtrasContainer = styled.div`
  display: grid;
  // grid-row: 3;

  // outline: 1px solid blue;
`;
const MenuExtrasLabel = styled.label`
  text-transform: capitalize;
  margin: 8px 16px 8px 0;
`;
const MenuExtrasSelect = styled.select`
  width: 100px;
  margin: 0 16px 16px 0;
`;
const MenuExtrasOption = styled.option``;
const AddToCartQtyContainer = styled.div`
  display: grid;
  grid-template-columns: 60px 20px;
  grid-row: 4;
  grid-column: 2;
  grid-gap: 5px;
  background: lightgreen;
  border-radius: 3px;
  width: 82px;
  padding-right: 18px;
  z-index: 10;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    > select {
      height: 20px;
    }
  }
`;

const ItemModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  /* transform: translateY(0); */
  width: 100vw;
  height: 100vh;
  background: lightblue;
  z-index: 25;
`;

//Categories
const CategoriesContainer = styled.div`
  position: sticky;
  top: 0px;
  background: white;
  display: inline-flex;
  width: calc(100vw - 40px);
  height: 50px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin: 12px 0;
  padding: 0 12px;
  z-index: 20;

  display: grid;
  grid-template-columns: repeat(
    ${({ categoryAmount }) => categoryAmount && categoryAmount},
    auto
  );
  // grid-gap: 20px;
  align-items: center;
  text-align: center;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;
const Category = styled.div`
  padding: 14px 30px;
  width: max-content;
  // margin: 0 12px;
  background: ${({ isActive }) => isActive && "rgba(0,0,0,0.1)"};
  cursor: pointer;
`;
