import { useEffect, useState } from "react";

const CollectionNotification = ({ orderId }) => {
  const [order, setOrder] = useState(null);
  const [orderStatus, setOrderStatus] = useState(false);

  useEffect(() => {
    const getOrderStatus = async (orderId) => {
      try {
        const response = await fetch(`/api/orders/getOrderById/${orderId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const order = await response.json();
        setOrder(order);
        console.log("ANDY fetch order", order);
        if (order.orderStatus === 1) {
          console.log("Order is ready for collection");
          setOrderStatus(true);
        } else {
          console.log("Order is NOT ready for collection");
        }
      } catch (err) {
        console.log("ANDY order fetch failed", err);
      }
    };

    const intervalId = setInterval(() => {
      if (!orderStatus) getOrderStatus(orderId);
    }, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [orderId, orderStatus]);

  return (
    <>
      {orderStatus
        ? `${order?.buyersName} YOUR ORDER IS READY FOR COLLECTION`
        : `YOUR ORDER IS BEING PREPARED`}
    </>
  );
};

export default CollectionNotification;
