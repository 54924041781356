import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../store/Header";
import CollectionNotification from "./CollectionNotification";
import { Item } from "components/checkout/Checkout";

import { clearCart } from "../../state/slices/cartSlice";

const OrderConfirmation = () => {
  const [order, setOrder] = useState(null);
  const [cartItems, setCartItems] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  console.log("ANDY url data", { urlParams, queryString });

  const orderId = urlParams.get("orderId");
  const deliveryMethod = urlParams.get("deliveryMethod");
  console.log("ANDY url params", { orderId, deliveryMethod });

  const { store } = useSelector((state) => state.store);
  const { items: cart } = useSelector((state) => state.cart);
  console.log("ANDY qweqweqweqwe cart", cart);
  // If cart is false, useEffect => /api/orders/getOrderById/${orderId}, and setCartItems(result)
  useEffect(() => {
    if (cart.length === 0) {
      const getOrderStatus = async (orderId) => {
        try {
          console.log("ANDY qweqweqweqwe", orderId);
          const response = await fetch(`/api/orders/getOrderById/${orderId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const order = await response.json();
          console.log("ANDY ORDERORDER", order);
          setOrder(order);
          setCartItems(order?.order);
        } catch (err) {
          console.log("ANDY order fetch failed", err);
        }
      };
      getOrderStatus(orderId);
    } else {
      setCartItems(cart);
    }

    setTimeout(() => {
      dispatch(clearCart());
    }, 3000);
  }, []);

  console.log("ANDY confirmation cartItems", { cartItems });

  return (
    <>
      <div
        onClick={() => navigate(-2)}
        style={{ display: "inline-flex", alignItems: "center", gap: "10px" }}
      >
        <div>{"<"}</div>
        <h3>Order Confirmation</h3>
      </div>
      <div>Sit back and relax</div>
      <div>Your order is on its way!</div>

      <div>Order Number: {orderId}</div>
      <div>
        Order:
        {cartItems?.map((item, i) => (
          <Item key={item?.id + i} item={item} i={i} />
        ))}
      </div>
      <div>
        {console.log("ANDY order?.deliveryMethod", order?.deliveryMethod)}
        {(deliveryMethod === "1" || order?.deliveryMethod === 1) && (
          <div>Table Service</div>
        )}
        {(deliveryMethod === "2" || order?.deliveryMethod === 2) && (
          <div>
            <CollectionNotification orderId={orderId} />
          </div>
        )}
      </div>
      <div>
        Order needs to be saved against current store in the Live Orders
        collection, so that user can come back to notification if they leave
        page
      </div>
      <div>
        Should collect as much data about order, user and user journey to be
        able to supply partners with analytics data
      </div>
    </>
  );
};
export default OrderConfirmation;
