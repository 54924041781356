import { useState, useEffect } from "react";
/**
 * ## Description
 * Return inner width of window initially and on resize
 * event of windows return new value
 */
export default function useWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    return () =>
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return width;
}
