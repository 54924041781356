import React from "react";
import { useSelector } from "react-redux";
import "../../styles.css";
import styled from "styled-components";
import {
  selectUser,
  selectIsAuthenticated,
} from "../../state/slices/authSlice";
import { Link } from "react-router-dom";

const Header = ({ layout, logo, title, subtitle, children }) => {
  const isUserAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser) ?? {};
  // const { accountType, firstName, storeName } = user || {};
  const { accountType, firstName, storeName } =
    isUserAuthenticated && user.value
      ? user.value
      : isUserAuthenticated && user
      ? user
      : {};

  console.log("ANDY session", {
    accountType,
    firstName,
    storeName,
    isUserAuthenticated,
    user,
  });

  return (
    <>
      <HeaderContainer
        className={"header-container " + layout?.header?.container}
      >
        {/* {logo.startsWith("<svg") ? <LogoSvg>{logo}</LogoSvg> : <LogoImg src={logo} />} */}
        <LogoImg
          src={logo}
          alt="logo"
          className={"header-logo " + layout?.header?.logo}
        />
        <HeaderTextContainer
          className={"header-textContainer " + layout?.header?.textContainer}
        >
          <HeaderTitle className={"header-title " + layout?.header?.title}>
            {title}
          </HeaderTitle>
          <HeaderSubtitle
            className={"header-subtitle " + layout?.header?.subtitle}
          >
            {subtitle}
          </HeaderSubtitle>
        </HeaderTextContainer>
        <Link
          to={{
            pathname:
              accountType === "User"
                ? "/account"
                : accountType === "Store"
                ? "/portal/overview"
                : "/login",
          }}
        >
          {accountType === "User"
            ? firstName
            : accountType === "Store"
            ? storeName
            : "Login"}
        </Link>
      </HeaderContainer>
      {children}
    </>
  );
};

export default Header;

//
//
//
//
//
//

//Header styles
const HeaderContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  align-items: center;
  justify-items: center;
`;
const LogoImg = styled.img`
  position: relative;
  width: 100px;
`;
const LogoSvg = styled.div``;
const HeaderTextContainer = styled.div``;
const HeaderTitle = styled.div`
  font-size: 30px;
  text-wrap: nowrap;
`;
const HeaderSubtitle = styled.div`
  font-size: 20px;
`;
