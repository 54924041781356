export function setLocalStorageWithExpiry(
  key,
  value,
  expiryInMinutes = 526000
) {
  const now = new Date();
  // Item includes the value and expiry time
  const item = {
    ...value,
    expiry: now.getTime() + expiryInMinutes * 60000,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorage(key) {
  const itemStr = localStorage.getItem(key);
  // Return null if the item doesn't exist
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  // Check if the item has expired
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item;
}

export function deleteLocalStorage(key) {
  localStorage.removeItem(key);
}
