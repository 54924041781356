import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      // Find the index of the item that matches the id
      const index = state.items.findIndex((item) => {
        return (
          item?.id === action.payload.id &&
          JSON.stringify(item?.payload?.itemExtras) ===
            JSON.stringify(action?.payload?.payload?.itemExtras)
        );
      });

      // If the item exists, update it
      if (index !== -1) {
        state.items[index] = {
          ...state.items[index],
          ...action.payload, // Merge the existing item with the new payload
        };
      } else {
        // If the item doesn't exist, add it to the array
        state.items.push(action.payload);
      }
    },
    updateItemQuantity: (state, action) => {
      const index = state.items.findIndex(
        (item) =>
          item.id === action.payload.id &&
          JSON.stringify(item.payload.itemExtras) ===
            JSON.stringify(action.payload.itemExtras)
      );
      if (index >= 0) {
        state.items[index].quantity = action.payload.quantity;
      }
    },
    removeItem: (state, action) => {
      state.items = state.items.filter(
        (item) =>
          !(
            item.id === action.payload.id &&
            JSON.stringify(item.payload.itemExtras) ===
              JSON.stringify(action.payload.itemExtras)
          )
      );
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addItem, updateItemQuantity, removeItem, clearCart } =
  cartSlice.actions;

export default cartSlice.reducer;
