import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid lightsteelblue;
`;

const Footer = () => {
  return <FooterContainer>Powered By Orderly</FooterContainer>;
};

export default Footer;
