export const quote = [
    `Build your tomorrow`,
    `Opportunities don't happen. You create them.`,
    `Don't be afraid to give up the good to go for the great`,
    `Successful people do what unsuccessful people are not willing to do`,
    `If you are not willing to risk the usual, you will have to settle for the ordinary`,
    `The ones who are crazy enough to think they can change the world, are the ones that do`,
    `All progress takes place outside the comfort zone`,
    `Do one thing every day that scares you`,
    `People who succeed have momentum`,
    `Don't let the fear of losing be greater than the excitement of winning`,
    `If you really look closely, most overnight successes took a long time`,
    `The way to get started is to quit talking and begin doing`,
    `Success seems to be connected with action. Successful people keep moving.`,
    `If you really want to do something, you'll find a way. If you don't, you'll find an excuse.`,
    `If you can dream it, you can do it`,
    `In order to succeed, we must first believe that we can`,
    `Every moment is a fresh beginning`,
    `Everything you can imagine is real`,
    `Have enough courage to start and enough heart to finish`,
    `Be so good they can't ignore you`,
    `Strive for greatness`,
]