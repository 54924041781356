import styled from "styled-components";

const Loader = styled.div`
  width: 35px;
  height: 80px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    inset: 0;
    padding: 3px 5px;
    border-top: 1px solid #bbb6aa;
    border-bottom: 4px solid #bbb6aa;
    background: linear-gradient(#612329 0 0) bottom no-repeat content-box,
      #e4e0d7;
    mix-blend-mode: darken;
    animation: l1 2s infinite linear;
  }
  &:before {
    content: "";
    position: absolute;
    inset: -18px calc(50% - 2px) 8px;
    background: #eb6b3e;
    transform-origin: bottom;
    transform: rotate(8deg);
  }
  @keyframes l1 {
    0% {
      background-size: 100% 100%;
    }
    100% {
      background-size: 100% 5%;
    }
  }
`;

const LoadingText = styled.div`
  --w: 10ch;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  line-height: 1.2em;
  letter-spacing: var(--w);
  width: var(--w);
  overflow: hidden;
  white-space: nowrap;
  color: #0000;
  animation: l17 2s infinite;

  &:before {
    content: "Loading...";
  }

  @keyframes l17 {
    0% {
      text-shadow: calc(0 * var(--w)) -1.2em #000,
        calc(-1 * var(--w)) -1.2em #000, calc(-2 * var(--w)) -1.2em #000,
        calc(-3 * var(--w)) -1.2em #000, calc(-4 * var(--w)) -1.2em #000,
        calc(-5 * var(--w)) -1.2em #000, calc(-6 * var(--w)) -1.2em #000,
        calc(-7 * var(--w)) -1.2em #000, calc(-8 * var(--w)) -1.2em #000,
        calc(-9 * var(--w)) -1.2em #000;
    }
    4% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) -1.2em #000,
        calc(-2 * var(--w)) -1.2em #000, calc(-3 * var(--w)) -1.2em #000,
        calc(-4 * var(--w)) -1.2em #000, calc(-5 * var(--w)) -1.2em #000,
        calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
        calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
    }
    8% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) -1.2em #000, calc(-3 * var(--w)) -1.2em #000,
        calc(-4 * var(--w)) -1.2em #000, calc(-5 * var(--w)) -1.2em #000,
        calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
        calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
    }
    12% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) -1.2em #000,
        calc(-4 * var(--w)) -1.2em #000, calc(-5 * var(--w)) -1.2em #000,
        calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
        calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
    }
    16% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) -1.2em #000, calc(-5 * var(--w)) -1.2em #000,
        calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
        calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
    }
    20% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) -1.2em #000,
        calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
        calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
    }
    24% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
        calc(-6 * var(--w)) -1.2em #000, calc(-7 * var(--w)) -1.2em #000,
        calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
    }
    28% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
        calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) -1.2em #000,
        calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
    }
    32% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
        calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
        calc(-8 * var(--w)) -1.2em #000, calc(-9 * var(--w)) -1.2em #000;
    }
    36% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
        calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
        calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) -1.2em #000;
    }
    40%,
    60% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
        calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
        calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
    }
    64% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
        calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
        calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 1.2em #000;
    }
    68% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
        calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
        calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
    }
    72% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
        calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 1.2em #000,
        calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
    }
    76% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
        calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
        calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
    }
    80% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 1.2em #000,
        calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
        calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
    }
    84% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
        calc(-4 * var(--w)) 1.2em #000, calc(-5 * var(--w)) 1.2em #000,
        calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
        calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
    }
    88% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 1.2em #000,
        calc(-4 * var(--w)) 1.2em #000, calc(-5 * var(--w)) 1.2em #000,
        calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
        calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
    }
    92% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
        calc(-2 * var(--w)) 1.2em #000, calc(-3 * var(--w)) 1.2em #000,
        calc(-4 * var(--w)) 1.2em #000, calc(-5 * var(--w)) 1.2em #000,
        calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
        calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
    }
    96% {
      text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 1.2em #000,
        calc(-2 * var(--w)) 1.2em #000, calc(-3 * var(--w)) 1.2em #000,
        calc(-4 * var(--w)) 1.2em #000, calc(-5 * var(--w)) 1.2em #000,
        calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
        calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
    }
    100% {
      text-shadow: calc(0 * var(--w)) 1.2em #000, calc(-1 * var(--w)) 1.2em #000,
        calc(-2 * var(--w)) 1.2em #000, calc(-3 * var(--w)) 1.2em #000,
        calc(-4 * var(--w)) 1.2em #000, calc(-5 * var(--w)) 1.2em #000,
        calc(-6 * var(--w)) 1.2em #000, calc(-7 * var(--w)) 1.2em #000,
        calc(-8 * var(--w)) 1.2em #000, calc(-9 * var(--w)) 1.2em #000;
    }
  }
`;

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        gap: "10px",
      }}
    >
      <Loader />
      <LoadingText />
    </div>
  );
};

export default Loading;
