// In your App.js or payment component

import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const Paypal = ({ orderAmount }) => {
  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "AcIzCe8P4kYcPACL7iZLzzGE9N1cV423e0BuZJ0f2ickHIGOPrUK9gPLEZ0N4WjjuALbaZBV07hFOoOw",
      }}
    >
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: orderAmount || null,
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            console.log("Transaction completed by " + details);
            // Handle the transaction success
          });
        }}
      />
    </PayPalScriptProvider>
  );
};

export default Paypal;
