import React, { useEffect } from "react";

// Hooks

import Hero from "./home/Hero";
import Explore from "./home/Explore";
import Expertise from "./home/Expertise";
import PricingBlock from "./home/PricingBlock";
import useWidth from "hooks/width.hook";

const Home = React.memo(() => {
  const width = useWidth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero />
      <Explore width={width} />
      <Expertise />
      <PricingBlock />
    </>
  );
});

export default Home;
