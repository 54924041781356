const getUserSession = () => {
  const session = localStorage.getItem("userSession");
  if (!session) return null;

  const userSession = JSON.parse(session);
  const currentTime = new Date().getTime();

  if (userSession.expiry > currentTime) {
    return userSession;
  } else {
    localStorage.removeItem("userSession"); // Clean up expired session
    return null;
  }
};

export default getUserSession;
