import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  store: [],
  table: 0,
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setStoreData: (state, action) => {
      state.store = action.payload;
    },
    setTable: (state, action) => {
      state.table = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStoreData, setTable } = storeSlice.actions;

export const getStoreData = (state) => state.store.store;

export default storeSlice.reducer;
