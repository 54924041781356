import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setUser, login } from "../../state/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { setLocalStorageWithExpiry } from "helpers/localStorage";
import md5 from "md5";

const CreateAccountForm = () => {
  const [accountType, setAccountType] = useState("User");
  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    verifyPassword: null,
    dob: null,
    // favouriteStore: { type: Schema.Types.ObjectId, ref: "Store" },
    // loyaltyStamps: [loyaltyStampSchema],
    accountType: accountType,
    // profileImageUrl: String,
    // phone: String,
    address: null,
    registrationDate: Date.now(),
    // lastLogin: Date,
    // isActive: { type: Boolean, default: true },
    // passwordResetToken: String,
    // passwordResetExpires: Date,
    securityQuestions: [
      // {
      //   question: String,
      //   answerHash: String,
      // },
    ],
  });
  const [passwordValidity, setPasswordValidity] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check password validity when the password field is changed
    if (name === "password") {
      validatePassword(value);
    }
  };

  const validatePassword = (password) => {
    const validity = {
      minLength: password?.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordValidity(validity);
  };

  const isPasswordValid = () => {
    return Object.values(passwordValidity).every(Boolean);
  };

  async function createOrUpdateUser(userData) {
    try {
      const response = await fetch("/api/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...userData,
          password: md5(userData.password),
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const updatedUser = await response.json();
      console.log("User created/updated successfully:", updatedUser);
      return updatedUser;
    } catch (error) {
      console.error("Error creating/updating user:", error);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isPasswordValid() && formData.password === formData.verifyPassword) {
      createOrUpdateUser(formData).then((response) => {
        if (response) {
          // If authentication is successful, set user and login
          dispatch(setUser(response));
          dispatch(login());
          setLocalStorageWithExpiry("userSession", response);

          // Navigate to the portal for 'Store' users or back to the previous page for others
          if (response?.accountType === "Store") {
            navigate("/portal/overview");
          } else {
            navigate(-1);
          }
        } else {
          alert("Handle endpoint Error");
        }
      });
    } else {
      alert("Please fulfill all password requirements.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Account Type:</label>
        <select
          name="accountType"
          value={accountType}
          onChange={(e) => {
            setAccountType(e.target.value);
            handleChange(e);
          }}
        >
          <option value="User">User</option>
          <option value="Store">Store</option>
        </select>
      </div>

      {accountType === "User" && (
        <>
          <div>
            <label>Name:</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Surname:</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
        </>
      )}

      {accountType === "Store" && (
        <>
          <div>
            <label>Store Name:</label>
            <input
              type="text"
              name="storeName"
              value={formData.storeName}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Address:</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
        </>
      )}

      <div>
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Password:</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
      </div>
      {formData?.password?.length > 0 && (
        <ul>
          <li style={{ color: passwordValidity.minLength ? "green" : "red" }}>
            At least 8 characters
          </li>
          <li style={{ color: passwordValidity.uppercase ? "green" : "red" }}>
            At least one uppercase letter
          </li>
          <li style={{ color: passwordValidity.lowercase ? "green" : "red" }}>
            At least one lowercase letter
          </li>
          <li style={{ color: passwordValidity.number ? "green" : "red" }}>
            At least one number
          </li>
          <li style={{ color: passwordValidity.specialChar ? "green" : "red" }}>
            At least one special character
          </li>
        </ul>
      )}
      <div>
        <label>Repeat Password:</label>
        <input
          type="password"
          name="verifyPassword"
          value={formData.verifyPassword}
          onChange={handleChange}
          style={{
            ...(formData?.verifyPassword?.length > 0 && {
              outline: `1px solid ${
                formData.verifyPassword === formData.password ? "green" : "red"
              }`,
            }),
          }}
        />
      </div>

      <button
        type="submit"
        disabled={
          !isPasswordValid() || formData.password !== formData.verifyPassword
        }
      >
        Create Account
      </button>
    </form>
  );
};

export default CreateAccountForm;
