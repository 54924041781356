import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51OwtBmDzdCj4a92nk5WPYTS3xkgt8ERSF8yX98JW993M8gfPbDvbRXdv79pyINgQ43zc0y5vWIvQmOrcYaK157gZ00aK4vqQUg"
); // Add secret key to env variable

const CheckoutForm = ({ orderAmount, onSubmit }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await fetch("/create-payment-intent", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount: orderAmount * 100, currency: "eur" }), // Amount in cents and specify currency
        });
        const data = await response.json();
        if (response.ok) {
          setClientSecret(data.clientSecret);
        } else {
          throw new Error(data.error || "Failed to fetch client secret");
        }
      } catch (error) {
        console.error("Error fetching client secret:", error);
        setError("Failed to initialize payment. Please try again.");
      }
    };

    fetchClientSecret();
  }, [orderAmount]);

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
        onSubmit();
      }
    } catch (error) {
      console.error("Error confirming card payment:", error);
      setError("Payment processing error. Please try again.");
      setProcessing(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement
        id="card-element"
        onChange={handleChange}
        options={{ hidePostalCode: true }}
      />
      <button disabled={processing || disabled || succeeded} id="submit">
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            `Pay €${orderAmount}`
          )}
        </span>
      </button>
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
    </form>
  );
};

const Stripe = ({ orderAmount, onSubmit }) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm orderAmount={orderAmount} onSubmit={onSubmit} />
  </Elements>
);

export default Stripe;
