import React, { useState } from "react";
import { logout } from "../../state/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Button = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      style={{ padding: "10px 20px", cursor: "pointer", margin: "5px" }}
    >
      {children}
    </button>
  );
};

export const Modal = ({ title = "", isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "5px",
          width: "500px",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <h2>{title}</h2>
        {children}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onClose}>Close</Button>
        </div>
      </div>
    </div>
  );
};

const UserAccount = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLoyaltyCard, setSelectedLoyaltyCard] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    // try {  - DOES NOT WORK
    //   const response = await fetch("/api/users/logout", { method: "POST" });
    //   const data = await response.json();

    //   if (response.ok) {
    //     dispatch(logout());
    //     localStorage.removeItem("userSession");

    //     console.log(data.message); // Logged out successfully
    //     navigate("/");
    //   } else {
    //     throw new Error(data.message);
    //   }
    // } catch (error) {
    //   console.error("Logout failed:", error);
    // }
    dispatch(logout());
    localStorage.removeItem("userSession");

    console.log("Logged out successfully"); // Logged out successfully
    navigate("/");
  };

  const user = {
    // get user data from DB/redux/localStorage
    name: "Andy",
    surname: "test",
    signUpDate: "2020-01-01",
    lastVisitedStore: "Starbucks - Finikoudes",
    mostFrequentStore: "Nero - Dali",
    loyaltyCards: [
      {
        name: "Unique Store Visits",
        stampsCollected: 0,
        stampsNeededForReward: 0,
      },
      {
        name: "Orderly Loyalty",
        stampsCollected: 5,
        stampsNeededForReward: 12,
      },
      { name: "Nero", stampsCollected: 10, stampsNeededForReward: 6 },
      { name: "Starbucks", stampsCollected: 3, stampsNeededForReward: 12 },
    ],
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedLoyaltyCard(null);
  };

  const selectLoyaltyCard = (card) => {
    setSelectedLoyaltyCard(card);
  };

  return (
    <div className="account-page">
      <h1>Account Details</h1>
      <p>
        <strong>Name:</strong> {user.name} {user.surname}
      </p>
      <p>
        <strong>Sign Up Date:</strong> {user.signUpDate}
      </p>
      <p>
        <strong>Last Visited Store:</strong> {user.lastVisitedStore}
      </p>
      <p>
        <strong>Most Frequently Visited Store:</strong> {user.mostFrequentStore}
      </p>
      <Button onClick={showModal}>Show Loyalty Cards</Button>
      <Modal title="Loyalty Cards" isOpen={isModalVisible} onClose={closeModal}>
        {selectedLoyaltyCard ? (
          <div>
            <h3>{selectedLoyaltyCard.name}</h3>
            <p>Total Stamps collected: {selectedLoyaltyCard.stampsCollected}</p>
            <p>
              Current Stamp collected:
              {selectedLoyaltyCard.stampsCollected <
              selectedLoyaltyCard.stampsNeededForReward
                ? selectedLoyaltyCard.stampsCollected
                : selectedLoyaltyCard.stampsCollected %
                  selectedLoyaltyCard.stampsNeededForReward}
            </p>
            <p>
              Stamps Needed for Reward:
              {selectedLoyaltyCard.stampsNeededForReward}
            </p>
            <Button onClick={() => setSelectedLoyaltyCard(null)}>
              Back to list
            </Button>
          </div>
        ) : (
          <ul>
            {user.loyaltyCards.map((card, index) => (
              <li
                key={index}
                style={{ cursor: "pointer", marginBottom: "10px" }}
                onClick={() => selectLoyaltyCard(card)}
              >
                {card.name}
              </li>
            ))}
          </ul>
        )}
      </Modal>
      <div>Rewards - Need to design flow for internal and external FE/BE</div>
      <div>Bday coupons/Anniversary coupons/milestone prizes or coupons</div>
      <div>User analytics</div>
      <div>Loyalty schemes</div>
      <div>Reference link</div>
      <div>Share to social media link</div>
      <Button onClick={() => handleLogout()}>Logout</Button>
    </div>
  );
};

export default UserAccount;
