import React from "react";
import styled from "styled-components";
import LazyLoad from "react-lazyload";

const Arrow = "https://picsum.photos/640/360";
const develop = "https://picsum.photos/640/360";
const explore = "https://picsum.photos/640/360";
const maintain = "https://picsum.photos/640/360";

const ExploreContainer = styled.div`
  display: inline-flex;
  width: 100%;
  height: 405px;
  margin: 80px auto 0;
  overflow-x: scroll;
  scroll-snap-type: inline mandatory;
  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 992px) {
    height: 412px;
    max-width: 1600px;

    & .lazyload-wrapper {
      width: 33.3%;
    }
  }
`;

const CardContainer = styled.div`
  width: 282px;
  height: 381px;
  margin: 0 15px;
  font-family: "Jost", Arial, sans-serif;
  font-size: 16px;
  scroll-snap-align: start;
  scroll-margin-left: 15px;

  @media screen and (min-width: 992px) {
    margin: 0 auto;
    width: 304px;
    height: 404px;
  }
`;

const CardImage = styled.div`
  width: 281px;
  height: 281px;

  @media screen and (min-width: 992px) {
    width: 304px;
    height: 312px;
  }

  background-image: url(${({ image }) =>
    image === "explore"
      ? `${explore}`
      : image === "develop"
      ? `${develop}`
      : image === "maintain"
      ? `${maintain}`
      : null});
  background-color: £{({image}) => !image && '#bebebe'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
const TitleArrow = styled.div`
  display: grid;
  grid-template-columns: calc(100% - 32px) 32px;
  margin: 10px 0;
  height: 32px;

  div {
    display: flex;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 23px;
    letter-spacing: 0.8px;
  }

  img {
    width: 32px;
    height: 32px;
  }
`;

const Text = styled.div`
  line-height: 24px;
  letter-spacing: 0.4px;
`;

const Explore = () => {
  return (
    <>
      <ExploreContainer>
        <LazyLoad height={400} once>
          <CardContainer>
            <CardImage image="explore" />
            <TitleArrow>
              <div>Explore</div>
              <div>
                <img src={Arrow} alt="arrow" />
              </div>
            </TitleArrow>
            <Text>
              Brainstorming with clients to create their ideal vision.
            </Text>
          </CardContainer>
        </LazyLoad>
        <LazyLoad height={400} once>
          <CardContainer>
            <CardImage image="develop" />
            <TitleArrow>
              <div>Develop</div>
              <div>
                <img src={Arrow} alt="arrow" />
              </div>
            </TitleArrow>
            <Text>
              Use cutting edge technology and the most up to date software to
              build your solution.
            </Text>
          </CardContainer>
        </LazyLoad>
        <LazyLoad height={400} once>
          <CardContainer>
            <CardImage image="maintain" />
            <TitleArrow>
              <div>Maintain</div>
            </TitleArrow>
            <Text>Providing personalised aftercare for our clients.</Text>
          </CardContainer>
        </LazyLoad>
      </ExploreContainer>
    </>
  );
};

export default Explore;
