import React from "react";
import styled from "styled-components";
import LazyLoad from "react-lazyload";

const SmallImage = "https://picsum.photos/640/360";
const MainImage = "https://picsum.photos/640/360";
import { Link } from "react-router-dom";

const PricingBlockContainer = styled.div`
  position: relative;
  width: calc(100% - 18px);
  height: 761px;
  margin: 56px auto 0;

  font-family: "Jost", Arial, sans-serif;

  @media screen and (min-width: 992px) {
    width: calc(100% - 64px);
    margin: 80px auto 0;
    height: 721px;
  }
`;
const BackgroundImage = styled.div`
  position: absolute;
  top: 113px;
  right: 0;
  width: 75%;
  height: 484px;
  z-index: -10;

  background-image: url(${MainImage});
  background-color: #bebebe;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (min-width: 992px) {
    top: 53px;
    width: 90%;
    height: 460px;

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SplitContainer = styled.div`
  width: 305px;
  height: 164px;
  background: #ffffff;

  -webkit-box-shadow: 5px 5px 18px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 5px 18px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 18px -10px rgba(0, 0, 0, 0.75);

  display: grid;
  grid-template-columns: 50% 50%;

  @media screen and (min-width: 992px) {
    width: 460px;
    height: 230px;
  }

  > div:first-child {
    background-image: url(${SmallImage});
    background-color: #bebebe;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  > div:last-child {
    display: grid;
    margin: 16px;

    @media screen and (min-width: 992px) {
      margin: 32px;
    }
  }
`;

const SplitTitle = styled.div`
  display: flex;
  align-items: center;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.8px;
  color: #444444;
  text-transform: uppercase;

  @media screen and (min-width: 992px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.45px;
  }
`;

const SplitDescription = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #444444;

  @media screen and (min-width: 992px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.8px;
  }
`;

const ButtonContainer = styled.div`
  @media screen and (min-width: 992px) {
    position: absolute;
    width: 90%;
    bottom: 50px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

const BrowseAllText = styled.div`
  width: 230px;
  margin: 445px 0 0;

  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 1.2px;
  color: #444444;
  text-transform: uppercase;

  @media screen and (min-width: 992px) {
    margin: 0;
  }
`;

const ViewAllButtonContainer = styled.div`
  width: 220px;
  margin: 16px 0 0;

  @media screen and (min-width: 992px) {
    margin: 16px 0 0 0;
  }
`;

export const ViewAllButton = styled.div`
  width: 147px;
  height: 48px;
  outline: 1px solid #444444;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 1.6px;
  color: #444444;
  background: rgba(0, 0, 0, 0);
  text-transform: uppercase;
  cursor: pointer;

  ${({ margin }) => margin && `margin: 16px 1px;`}

  @media screen and (min-width: 992px) {
    ${({ margin }) => margin && `margin: ${margin};`}
  }

  &:hover {
    color: white;
    background: rgba(0, 0, 0, 0.8);
  }
  transition: all 0.2s ease-in-out;
`;

const PricingBlock = () => {
  return (
    <PricingBlockContainer>
      <LazyLoad height={400} once>
        <BackgroundImage />
        <SplitContainer>
          <div></div>
          <div>
            <SplitTitle>Pricing</SplitTitle>
            <SplitDescription>Start your journey today</SplitDescription>
          </div>
        </SplitContainer>
      </LazyLoad>
      <ButtonContainer>
        <BrowseAllText>Browse all of our price plans</BrowseAllText>
        <ViewAllButtonContainer>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={"/pricing"}
          >
            <ViewAllButton>View all</ViewAllButton>
          </Link>
        </ViewAllButtonContainer>
      </ButtonContainer>
    </PricingBlockContainer>
  );
};

export default PricingBlock;
