//styles
import "./styles.css";

import { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";

//redux
import { store } from "./state/store";
import { Provider } from "react-redux";

//components
import Home from "./components/Home";
import StoreMenu from "./components/store/StoreMenu";
import Loading from "./components/Loading";

const Checkout = lazy(() => import("./components/checkout/Checkout"));
const OrderConfirmation = lazy(() =>
  import("./components/order-confirmation/OrderConfirmation")
);
const Login = lazy(() => import("./components/login/Login"));
const UserAccount = lazy(() => import("./components/account/UserAccount"));
const Portal = lazy(() => import("./components/portal/Portal"));
const Pricing = lazy(() => import("./components/home/Pricing"));
// const ContactPage = lazy(() => import("./components/home/ContactPage"));
const NotFound = lazy(() => import("./components/not-found/NotFound"));

//helpers
import ProtectedRoute from "./helpers/ProtectedRoute";
import SessionHandler from "components/SessionHandler";
import useWidth from "hooks/width.hook";

export default function App() {
  const width = useWidth();

  return (
    <Provider store={store}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Orderly</title>
        {/* <link rel="icon" type="image/x-icon" href="/images/favicon.ico" /> */}
        <link rel="canonical" href="http://orderlyhub.com" />
      </Helmet>
      <Router>
        <SessionHandler />
        <Suspense fallback={<Loading />}>
          <Routes>
            {/* <Route
              path="/"
              element={
                urlParams.size > 0 && loading ? (
                  <Loading />
                ) : storeData ? (
                  <StoreMenu store={storeData} />
                ) : (
                  <Main />
                )
              }
            /> */}
            <Route path="/" element={<Home />} />
            <Route path="/store/*" element={<StoreMenu />} />
            <Route path="/login" element={<Login />} />
            <Route path="/account" element={<UserAccount />} />
            <Route
              path="/portal/*"
              element={
                <ProtectedRoute>
                  <Portal />
                </ProtectedRoute>
              }
            />
            <Route path="/pricing" element={<Pricing width={width} />} />
            <Route
              path="/contact"
              element={<div width={width}>CONTACT PAGE</div>}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </Provider>
  );
}
