import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUser, login } from "state/slices/authSlice";
import getUserSession from "helpers/getUserSession";

function SessionHandler() {
  const dispatch = useDispatch();

  useEffect(() => {
    const session = getUserSession();
    if (session) {
      dispatch(setUser(session));
      dispatch(login());
    }
  }, [dispatch]);

  return null; // This component does not render anything
}

export default SessionHandler;
