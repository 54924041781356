import React from "react";
import styled from "styled-components";
import LazyLoad from "react-lazyload";

const ExpertiseContainer = styled.div`
  font-family: "Jost", Arial, sans-serif;
  margin-top: 80px;

  @media screen and (min-width: 992px) {
    margin-top: 0px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2px;
  }
`;

const ExpertiseBlockHeader = styled.div`
  /* width: 430px; */
  margin: 64px auto 32px;
  text-align: center;

  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 1.6px;
  color: #444444;
  text-transform: uppercase;

  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
`;

const ExpertiseCard = styled.div`
  width: 100%;
  height: 431px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;

  background-image: ${({ image }) => image && `url(${image})`};
  background-color: #bebebe;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (min-width: 992px) {
    height: 560px;
  }
`;
const ExpertiseOverlay = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  height: 100%;
  background: rgba(00, 00, 00, 0.5);
`;

const ExpertiseTitle = styled.div`
  display: grid;
  align-items: flex-end;
  width: 266px;
  height: 100%;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 1.6px;
  color: #ffffff;
  text-transform: uppercase;
`;

const SubtitleArrowContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  width: 266px;
  height: 100%;
  margin: 20px auto 0;
`;

const Subtitle = styled.div`
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.8px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
`;

const Expertise = () => {
  return (
    <ExpertiseContainer>
      <ExpertiseBlockHeader>Expertise</ExpertiseBlockHeader>
      <LazyLoad height={400} once>
        <ExpertiseCard image={"https://picsum.photos/640/360"}>
          <ExpertiseOverlay>
            <ExpertiseTitle>SEO</ExpertiseTitle>
            <SubtitleArrowContainer>
              <Subtitle>Drive traffic to your website.</Subtitle>
            </SubtitleArrowContainer>
          </ExpertiseOverlay>
        </ExpertiseCard>
      </LazyLoad>
      <LazyLoad height={400} once>
        <ExpertiseCard image={"https://picsum.photos/640/360"}>
          <ExpertiseOverlay>
            <ExpertiseTitle>UX/UI</ExpertiseTitle>
            <SubtitleArrowContainer>
              <Subtitle>
                Modern, sleek or quirky. Whatever you're after, our experts can
                build it.
              </Subtitle>
            </SubtitleArrowContainer>
          </ExpertiseOverlay>
        </ExpertiseCard>
      </LazyLoad>
      <LazyLoad height={400} once>
        <ExpertiseCard image={"https://picsum.photos/640/360"}>
          <ExpertiseOverlay>
            <ExpertiseTitle>Performance</ExpertiseTitle>
            <SubtitleArrowContainer>
              <Subtitle>
                Using the latest tech to ensure your solution won't lag behind.
              </Subtitle>
            </SubtitleArrowContainer>
          </ExpertiseOverlay>
        </ExpertiseCard>
      </LazyLoad>
    </ExpertiseContainer>
  );
};

export default Expertise;
